import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Chevron } from 'components/icons';

import {
  ArrowButton,
  CarouselRowContainer,
  CarouselItem,
  CarouselRowInner,
  ThumbnailLink,
  ThumbnailsGrid,
  ThumbnailContent,
  ThumbnailDot,
  ArrowWrapper,
} from './styles';
import { CarouselRowProps } from './types';

export { CarouselItem };

export function CarouselRow({
  arrowLayout,
  className,
  inline,
  snap,
  children,
  thumbnails,
}: CarouselRowProps) {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const [availableArrows, setAvailableArrows] = useState({
    left: false,
    right: false,
  });

  const innerRef = useRef<HTMLDivElement>(null);

  const arrowJump = useCallback((direction: 'left' | 'right') => {
    if (innerRef.current) {
      const width = innerRef.current.offsetWidth;
      innerRef.current.scrollBy({
        left: direction === 'right' ? width : width - width * 2,
        behavior: 'smooth',
      });
    }
  }, []);

  const thumbnailJump = useCallback((index: number) => {
    if (innerRef.current) {
      const width = innerRef.current.offsetWidth;

      innerRef.current.scrollTo({
        left: index * width,
        behavior: 'smooth',
      });
    }
  }, []);

  const scrollListener = useCallback(() => {
    if (innerRef.current) {
      setActiveItem(
        Math.round(innerRef.current.scrollLeft / innerRef.current.offsetWidth),
      );

      setAvailableArrows({
        left: innerRef.current.scrollLeft > 0,
        right:
          innerRef.current.scrollLeft <
          innerRef.current.scrollWidth - innerRef.current.offsetWidth,
      });
    }
  }, []);

  useEffect(() => {
    scrollListener();
  }, [scrollListener]);

  return (
    <>
      {arrowLayout === 'top' &&
      (availableArrows.left || availableArrows.right) ? (
        <ArrowWrapper>
          <ArrowButton
            type="button"
            onClick={() => arrowJump('left')}
            disabled={!availableArrows.left}
            arrowLayout={arrowLayout}
            aria-label="Previous image"
          >
            <Chevron />
          </ArrowButton>
          <ArrowButton
            type="button"
            onClick={() => arrowJump('right')}
            disabled={!availableArrows.right}
            arrowLayout={arrowLayout}
            aria-label="Next image"
          >
            <Chevron />
          </ArrowButton>
        </ArrowWrapper>
      ) : null}
      <CarouselRowContainer className={className}>
        {arrowLayout &&
        arrowLayout !== 'top' &&
        (availableArrows.left || availableArrows.right) ? (
          <ArrowButton
            type="button"
            onClick={() => arrowJump('left')}
            disabled={!availableArrows.left}
            arrowLayout={arrowLayout}
            aria-label="Previous image"
          >
            <Chevron />
          </ArrowButton>
        ) : null}
        <CarouselRowInner
          onScroll={arrowLayout ? scrollListener : undefined}
          ref={innerRef}
          inline={inline}
          snap={snap}
        >
          {children}
        </CarouselRowInner>
        {arrowLayout &&
        arrowLayout !== 'top' &&
        (availableArrows.left || availableArrows.right) ? (
          <ArrowButton
            type="button"
            onClick={() => arrowJump('right')}
            disabled={!availableArrows.right}
            arrowLayout={arrowLayout}
            aria-label="Next image"
          >
            <Chevron />
          </ArrowButton>
        ) : null}
      </CarouselRowContainer>
      {thumbnails && thumbnails.length > 1 ? (
        <ThumbnailsGrid>
          {thumbnails.map((thumbnail, index) => (
            <ThumbnailLink
              onClick={() => thumbnailJump(index)}
              type="button"
              key={index}
              aria-label={`Go to image ${index + 1}`}
            >
              <ThumbnailContent>{thumbnail}</ThumbnailContent>
              <ThumbnailDot active={activeItem === index} />
            </ThumbnailLink>
          ))}
        </ThumbnailsGrid>
      ) : null}
    </>
  );
}
